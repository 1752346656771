/* --Font-Family-- */
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap");
/* --Font-Weight-- */
/* --Colors-- */
/* --USAGE-- */
html {
  font-size: 55.5%; }
  @media (min-width: 992px) {
    html {
      font-size: 58.5%; } }
  @media (min-width: 1200px) {
    html {
      font-size: 62.5%; } }

h1,
.h1 {
  font-family: "Montserrat", sans-serif;
  color: #da402f;
  font-style: normal;
  font-weight: 700;
  font-size: 3.3rem;
  line-height: 1.2;
  text-transform: uppercase; }

h2,
.h2 {
  font-family: "Montserrat", sans-serif;
  color: #da402f;
  font-style: normal;
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 1.4; }

h3,
.h3 {
  font-family: "Montserrat", sans-serif;
  color: #2e1f1a;
  font-style: normal;
  font-weight: 700;
  font-size: 1.7rem;
  line-height: 1.2;
  text-transform: uppercase; }

h4,
.h4 {
  font-family: "Montserrat", sans-serif;
  color: #da402f;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  text-transform: none;
  line-height: 1.5; }

h5,
.h5 {
  font-family: "Montserrat", sans-serif;
  color: #2e1f1a;
  font-style: normal;
  font-weight: 500;
  font-size: 1.7rem;
  text-transform: none;
  line-height: 1.5; }

h6,
.h6 {
  font-family: "Montserrat", sans-serif;
  color: #2e1f1a;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.5; }

a {
  font-family: "Montserrat", sans-serif;
  color: #da402f;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem; }
  a:hover, a:focus, a:active, a.active {
    color: #7f2412;
    text-decoration: none; }

p {
  font-family: "Montserrat", sans-serif;
  color: #2e1f1a;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8; }
  p a:hover {
    text-decoration: none; }

.line-title-page {
  background: #7f2412;
  height: 160px; }
  .line-title-page h1 {
    color: #fff;
    margin-bottom: 0;
    display: flex;
    align-items: center; }
  .line-title-page * {
    height: 100%; }

.line-banner-interne {
  margin-bottom: 3rem; }
  .line-banner-interne .mod-banner__img {
    height: 500px; }
  .line-banner-interne .mod-banner__content {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 768px) {
      .line-banner-interne .mod-banner__content {
        width: 732px; } }
    @media (min-width: 992px) {
      .line-banner-interne .mod-banner__content {
        width: 952px; } }
    @media (min-width: 1200px) {
      .line-banner-interne .mod-banner__content {
        width: 1152px; } }
    .line-banner-interne .mod-banner__content .wrapper {
      max-width: 736.5px;
      background: #fff;
      padding: 60px; }

.btn {
  font-family: "Montserrat", sans-serif;
  box-shadow: none !important;
  max-width: 100%;
  min-width: 1px;
  border: none;
  text-decoration: none;
  padding: 6px 12px;
  font-size: 1.1rem; }

.btn-primary {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  color: white;
  background-color: #da402f;
  border: 2px solid #da402f; }
  .btn-primary:hover, .btn-primary:focus {
    color: #fff;
    background-color: #7f2412;
    border-color: #7f2412; }

.btn.btn-default {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  color: white;
  background-color: #2e1f1a;
  border: 2px solid #2e1f1a; }
  .btn.btn-default:hover, .btn.btn-default:focus {
    color: #fff;
    background-color: #64524b;
    border-color: #64524b; }

.btn.btn-link {
  position: relative;
  color: #726969;
  font-weight: 800;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
  height: 100%; }
  .btn.btn-link:hover, .btn.btn-link:focus, .btn.btn-link:active, .btn.btn-link.active {
    color: #da402f; }

form h2 {
  color: #2e1f1a; }

form label {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4;
  margin-bottom: 1rem !important; }

.line-menu {
  background: #fff; }
  .line-menu .row-fluid.row {
    display: flex;
    align-items: stretch;
    padding: 0 15px;
    height: 110px;
    flex-direction: row; }
    @media (max-width: 991px) {
      .line-menu .row-fluid.row {
        display: block; } }
  .line-menu .zone-menu {
    position: relative;
    z-index: 9999999999;
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 991px) {
      .line-menu .zone-menu {
        width: 100%; }
        .line-menu .zone-menu .navbar.navbar-default.hidden-print {
          width: 100%; }
          .line-menu .zone-menu .navbar.navbar-default.hidden-print .navbar-nav {
            margin: 0;
            background: #f1eae8;
            position: relative;
            z-index: 999999; }
          .line-menu .zone-menu .navbar.navbar-default.hidden-print .navbar-collapse.collapse.in {
            background: #f1eae8; } }
  @media (max-width: 991px) {
    .line-menu .zone-header {
      width: 100%; } }
  .line-menu .navbar-default .navbar-nav > li > .dropdown-menu > li > a,
  .line-menu .navbar-default .navbar-nav > li > a {
    color: #2e1f1a; }
  .line-menu::after {
    border: none; }
  .line-menu ul.nav.navbar-nav li {
    padding: 15px 0px;
    border-bottom: 2px solid transparent;
    display: flex; }
    @media (max-width: 991px) {
      .line-menu ul.nav.navbar-nav li {
        flex-direction: column;
        border: none; } }
    .line-menu ul.nav.navbar-nav li.active, .line-menu ul.nav.navbar-nav li:hover {
      background: #f1eae8;
      border-bottom: 2px solid #da402f; }
      @media (max-width: 991px) {
        .line-menu ul.nav.navbar-nav li.active, .line-menu ul.nav.navbar-nav li:hover {
          border: none; } }
      .line-menu ul.nav.navbar-nav li.active a, .line-menu ul.nav.navbar-nav li:hover a {
        color: #2e1f1a; }
    .line-menu ul.nav.navbar-nav li a {
      display: flex;
      align-items: flex-end; }
      .line-menu ul.nav.navbar-nav li a.dropdown-toggle {
        align-items: center; }

.line-menu .nav.navbar-nav .dropdown-menu > li {
  background: #fff; }
  .line-menu .nav.navbar-nav .dropdown-menu > li:hover {
    border-color: transparent;
    background: #fff; }
    .line-menu .nav.navbar-nav .dropdown-menu > li:hover a {
      background: #fff;
      color: #da402f; }

@media (max-width: 991px) {
  a.dropdown-toggle {
    position: absolute;
    width: 12%;
    padding: 26px; }
    a.dropdown-toggle .caret {
      margin: auto; } }

.panel-heading,
.panel.panel-default.panel-news-accordion {
  background: #fff !important;
  border: 1px solid #5f7783; }

.panel-default > .panel-heading,
#accordion h3.panel-title {
  border: none; }

.panel.panel-default.panel-news-accordion:nth-child(even) h3.panel-title,
.panel.panel-default.panel-news-accordion:nth-child(even) p.news-accordion-published-date {
  color: #da402f; }

.member-sidebar__inner,
ul.nav.nav-stacked {
  background-color: #da402f; }

@media (min-width: 768px) {
  ul.nav.nav-stacked {
    width: 100%; } }

ul.nav.nav-stacked li:hover {
  background: #fff; }

ul.nav.nav-stacked a {
  width: 100%;
  text-align: left; }
  ul.nav.nav-stacked a:hover {
    background: transparent; }

.member-sidebar__member-header {
  border-bottom: none;
  padding-left: 0;
  padding-right: 0;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1.2rem; }
  .member-sidebar__member-header .h3 {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.4;
    color: #fff;
    margin: 0;
    text-transform: none; }

.member-sidebar__inner {
  padding-bottom: 0rem; }
  .member-sidebar__inner ul {
    padding: 0; }
    .member-sidebar__inner ul li {
      padding: 0.5rem 2rem;
      border-bottom: 8px solid #fff;
      border: none; }
      .member-sidebar__inner ul li.active a {
        text-decoration: none;
        color: #fff; }
      .member-sidebar__inner ul li a {
        font-style: normal;
        font-weight: 600;
        font-size: 1.3rem;
        text-decoration: none;
        color: #fff;
        text-transform: uppercase; }
        .member-sidebar__inner ul li a:hover {
          color: #fff; }
      .member-sidebar__inner ul li:hover, .member-sidebar__inner ul li.active {
        background: #fff; }
        .member-sidebar__inner ul li:hover a, .member-sidebar__inner ul li.active a {
          color: #2e1f1a; }

.member-sidebar__member-footer {
  background-color: #da402f;
  color: #ffffff;
  font-style: normal;
  font-weight: 800;
  font-size: 1.2rem;
  line-height: normal;
  margin: 2rem 0 0 0;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 2rem;
  border-top: none;
  border-bottom: none;
  background: #000; }
  .member-sidebar__member-footer:hover {
    background: #726969; }
  .member-sidebar__member-footer a {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    padding-top: 0;
    padding-bottom: 3rem; }
    .member-sidebar__member-footer a:hover {
      color: #fff; }

.line-annuaire-membre {
  padding: 4rem 3rem; }
  .line-annuaire-membre .js-directory-search-box.col-sm-8 {
    padding: 3rem;
    background: #f1eae8; }
    @media (min-width: 768px) {
      .line-annuaire-membre .js-directory-search-box.col-sm-8 {
        max-width: 350px; } }
    .line-annuaire-membre .js-directory-search-box.col-sm-8 .col-md-12 {
      width: 100%; }
  .line-annuaire-membre .mod_directory__result_bloc {
    margin-top: 5rem; }
    @media (min-width: 768px) {
      .line-annuaire-membre .mod_directory__result_bloc {
        margin-left: 5rem;
        margin-top: 0rem; } }
    .line-annuaire-membre .mod_directory__result_bloc a.nav-link.active {
      border: none;
      border-bottom: 2px solid #da402f;
      color: #da402f;
      background: #f1eae8; }
  .line-annuaire-membre .nav-tabs {
    border-bottom: none; }
  .line-annuaire-membre .col-sm-16 {
    padding: 0; }

form.paginationControl {
  display: flex;
  justify-content: flex-end; }

div#directory-member-box h2.section-title {
  color: #da402f; }

div#directory-member-box form#Ficheannuaire .row {
  display: flex;
  flex-direction: column; }

div#directory-member-box #section_125275 .col-sm-12 {
  background: #f1eae8;
  padding: 0 2.4rem;
  width: 100%; }
  @media (min-width: 768px) {
    div#directory-member-box #section_125275 .col-sm-12 {
      width: 70%; } }
  div#directory-member-box #section_125275 .col-sm-12 label {
    font-weight: bold; }

div#directory-member-box .form-element-group_logo label {
  display: none; }

div#directory-member-box #section_127121 .row,
div#directory-member-box #section_127117 .row,
div#directory-member-box #section_128496 .row {
  flex-direction: row !important; }
  div#directory-member-box #section_127121 .row .col-sm-12,
  div#directory-member-box #section_127117 .row .col-sm-12,
  div#directory-member-box #section_128496 .row .col-sm-12 {
    width: 50%; }

div#directory-member-box #section_128497 .col-sm-12 {
  width: 100%; }
  @media (min-width: 768px) {
    div#directory-member-box #section_128497 .col-sm-12 {
      width: 70%; } }
  div#directory-member-box #section_128497 .col-sm-12 .form-control-static.form-control-preview {
    max-width: none;
    width: 100%; }

.line-banner .mod-banner__img {
  height: 550px; }

ul.mod-header.mod-header-wrapper {
  display: flex;
  align-items: center;
  padding: 0; }
  ul.mod-header.mod-header-wrapper li.header-ext.header-ext-custom {
    justify-self: start; }
    ul.mod-header.mod-header-wrapper li.header-ext.header-ext-custom a {
      line-height: 1; }
  ul.mod-header.mod-header-wrapper li.header-ext.header-ext-login {
    margin-left: auto; }

.line-header {
  background: #f1eae8;
  height: 40px; }
  .line-header * {
    height: 40px; }
  .line-header a.-forum-discussion,
  .line-header a.nous-suivre,
  .line-header .mod-social-links,
  .line-header span.icon-facebook,
  .line-header span.icon-linkedin,
  .line-header .member-register,
  .line-header .member-login {
    display: flex;
    align-items: center;
    padding: 10px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 800; }
  .line-header a.-forum-discussion,
  .line-header a.nous-suivre,
  .line-header .member-register,
  .line-header .member-login {
    padding: 15px 40px; }

a.-forum-discussion {
  background: #7f2412;
  color: #fff;
  transition: 0.2s ease-in; }
  a.-forum-discussion:hover {
    background: #48170d; }

a.nous-suivre {
  color: #2e1f1a; }

.member-register {
  background: #da402f;
  color: #fff !important;
  transition: 0.2s ease-in; }
  .member-register:hover {
    background: #ab2f21; }

span.separator {
  display: none !important; }

.member-login {
  background: #2e1f1a;
  color: #fff !important;
  transition: 0.2s ease-in; }
  .member-login:hover {
    background: #64524b; }

li.header-ext.header-ext-custom,
li.header-ext.header-ext-login {
  display: flex; }

.mod-header-logo {
  padding-left: 15px; }

.line-footer {
  background: black;
  padding: 0; }
  .line-footer p {
    color: #fff; }
  .line-footer .mod-article__content,
  .line-footer .media-icon {
    display: flex;
    align-items: center;
    justify-content: center; }
  .line-footer .container-footer {
    padding: 3rem 0; }
  .line-footer h4 {
    color: #fff; }
  .line-footer p, .line-footer h4, .line-footer a {
    text-align: center; }

.page-membre .line-banner-membre .mod-banner__img {
  height: 500px; }

.page-membre .mod-banner__content {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .page-membre .mod-banner__content {
      width: 732px; } }
  @media (min-width: 992px) {
    .page-membre .mod-banner__content {
      width: 952px; } }
  @media (min-width: 1200px) {
    .page-membre .mod-banner__content {
      width: 1152px; } }
  .page-membre .mod-banner__content .wrapper {
    max-width: 736.5px;
    background: #fff;
    padding: 60px; }

.page-membre .line-publicite {
  background: #fff; }
  .page-membre .line-publicite .zone-news_flash img.e-thumbnail {
    width: 100%;
    height: 320px !important;
    object-fit: cover; }

.page-home .line-call-to-action {
  background: #f1eae8;
  height: 650px; }
  @media (min-width: 1200px) {
    .page-home .line-call-to-action {
      height: 400px; } }
  @media (max-width: 767px) {
    .page-home .line-call-to-action {
      height: 1000px; } }
  @media (max-width: 571px) {
    .page-home .line-call-to-action {
      height: 1200px; } }
  .page-home .line-call-to-action .card-group {
    display: block;
    flex-direction: row;
    position: absolute;
    top: -300px;
    width: 100%; }
    @media (min-width: 1200px) {
      .page-home .line-call-to-action .card-group {
        display: flex; } }
    @media (max-width: 767px) {
      .page-home .line-call-to-action .card-group {
        left: 0; } }
  .page-home .line-call-to-action .card-big {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 45px 66px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
    margin-right: 30px;
    margin-right: 0; }
    @media (min-width: 1200px) {
      .page-home .line-call-to-action .card-big {
        width: 544px;
        margin-right: 30px; } }
    @media (max-width: 767px) {
      .page-home .line-call-to-action .card-big {
        padding: 45px 15px; } }
    .page-home .line-call-to-action .card-big h1 {
      margin-bottom: 40px; }
  .page-home .line-call-to-action .wrap-small {
    display: flex;
    align-items: flex-end; }
    @media (max-width: 767px) {
      .page-home .line-call-to-action .wrap-small {
        flex-direction: column;
        align-items: center; } }
  .page-home .line-call-to-action .card-small {
    width: 280px;
    margin: 0 4px;
    position: relative;
    margin-top: 3rem;
    margin-right: 2rem; }
    @media (min-width: 1200px) {
      .page-home .line-call-to-action .card-small {
        margin-top: 0;
        margin-right: 0; } }
    @media (max-width: 767px) {
      .page-home .line-call-to-action .card-small {
        margin: 3rem auto; } }
    .page-home .line-call-to-action .card-small img {
      width: 100% !important;
      height: 366px !important; }
    .page-home .line-call-to-action .card-small .card-footer {
      position: relative;
      z-index: 999;
      transition: 0.3s ease-in;
      position: absolute;
      height: 100px;
      background: rgba(218, 64, 47, 0.8);
      bottom: 0;
      width: 100%;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: bold; }
    .page-home .line-call-to-action .card-small:hover {
      cursor: pointer; }
      .page-home .line-call-to-action .card-small:hover .card-footer {
        background: rgba(127, 36, 18, 0.8); }

.page-home .line-publicite {
  background: #da402f;
  padding: 6rem 0; }
  .page-home .line-publicite .container-publicitaire {
    display: flex;
    flex-direction: row; }
    @media (max-width: 767px) {
      .page-home .line-publicite .container-publicitaire {
        flex-direction: column;
        padding: 15px; } }
  .page-home .line-publicite .information h2,
  .page-home .line-publicite .information p {
    color: #fff; }
  .page-home .line-publicite .information .btn.btn-primary {
    background: #fff;
    color: #da402f; }
    .page-home .line-publicite .information .btn.btn-primary:hover {
      color: #fff;
      background: #7f2412; }
  .page-home .line-publicite .zone-100.zone-html {
    padding: 0; }

.page-home .line-title-nouveau-membre {
  padding-top: 7rem; }

.page-home .line-nouveau-membre {
  padding-bottom: 14rem; }
  .page-home .line-nouveau-membre .content.new-members-list {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 480px) {
      .page-home .line-nouveau-membre .content.new-members-list {
        justify-content: center; } }
  .page-home .line-nouveau-membre .item-vue-membre {
    position: relative;
    display: block;
    margin-right: 2rem;
    width: 140px;
    height: 140px;
    margin-bottom: 8rem; }
    .page-home .line-nouveau-membre .item-vue-membre > img {
      display: block;
      width: 140px;
      height: auto !important;
      margin-top: 50%;
      transform: translateY(-50%); }
      .page-home .line-nouveau-membre .item-vue-membre > img[src=''] {
        opacity: 0;
        width: 140px;
        height: 140px !important; }
    .page-home .line-nouveau-membre .item-vue-membre a {
      position: absolute;
      display: block;
      width: 140px;
      height: 140px;
      top: 0;
      left: 0;
      padding-top: 150px; }

.page-home .line-partenaires {
  padding: 8rem 0 10rem; }
  .page-home .line-partenaires .zone-new_members {
    padding: 0 0 0 15px; }
    @media (max-width: 1199px) {
      .page-home .line-partenaires .zone-new_members {
        padding: 0; } }
  @media (max-width: 1199px) {
    .page-home .line-partenaires h2 {
      padding-left: 15px; } }
  .page-home .line-partenaires .col-sm-8 {
    padding: 0; }
    @media (max-width: 1199px) {
      .page-home .line-partenaires .col-sm-8 {
        padding: 0 15px;
        text-align: left !important;
        margin-bottom: 15px; } }
    .page-home .line-partenaires .col-sm-8:nth-child(2) {
      text-align: center; }
    .page-home .line-partenaires .col-sm-8:nth-child(3) {
      text-align: right; }
  .page-home .line-partenaires img {
    width: 354px; }
  .page-home .line-partenaires .viewAll {
    display: none; }

.page-information .line-banner-information .mod-banner__img {
  height: 500px; }

.page-information .mod-banner__content {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .page-information .mod-banner__content {
      width: 732px; } }
  @media (min-width: 992px) {
    .page-information .mod-banner__content {
      width: 952px; } }
  @media (min-width: 1200px) {
    .page-information .mod-banner__content {
      width: 1152px; } }
  .page-information .mod-banner__content .wrapper {
    max-width: 736.5px;
    background: #fff;
    padding: 60px; }

.page-information .line-publicite {
  background: #fff;
  padding: 6rem 0; }
  .page-information .line-publicite .zone-news_flash img.e-thumbnail {
    width: 100%;
    height: 320px !important;
    object-fit: cover; }

.page-propos .line-main .e-thumbnail {
  width: 100%;
  height: 320px !important;
  object-fit: cover; }

.page-contact .form-group.form-element-message .col-md-12 {
  width: 100%; }

@media (max-width: 991px) {
  .line-menu .container-fluid {
    padding: 0; }
  button.navbar-toggle.collapsed {
    position: absolute;
    top: -75px;
    right: 15px; }
  button.navbar-toggle {
    position: absolute;
    top: -75px;
    right: 15px; }
  div#OMwGIPByz1NEb4DdPEm745iZ {
    padding-left: 0;
    padding-right: 0 !important; }
  .line-menu .row-fluid.row {
    padding: 0;
    margin: 0; }
  #8WHD3noH186b1GQubS5Mnuf2 {
    padding-left: 15px; }
  .navbar-collapse.collapse.in a {
    width: 100%; }
    .navbar-collapse.collapse.in a.dropdown-toggle {
      position: absolute;
      width: 12%;
      padding: 26px; }
      .navbar-collapse.collapse.in a.dropdown-toggle .caret {
        margin: auto; } }

@media (max-width: 768px) {
  li.header-ext.header-ext-login {
    display: flex;
    justify-content: center; } }

.form-withProgress__navigation-footer .btn-link:hover {
  background: transparent !important; }

/******* ajout Adrien fin fevrier*/
img.news-thumbnail {
  width: 100%;
  height: 200px !important;
  object-fit: cover; }

img.e-thumbnail {
  width: 100%;
  height: 400px !important;
  object-fit: cover; }

span.member-greeting {
  margin-right: 10px;
  margin-top: 10px; }

a.member-myprofile {
  margin-right: 10px;
  margin-top: 10px; }

a.member-logout {
  margin-right: 10px;
  margin-top: 10px; }
